import React from 'react';
import styled from 'styled-components';
import AdButler from '../../components/ThankyouPages/AdButler';
import r2oLogo from '../../images/r2ologo.png';
import withThankyouWrapper from '../../components/ThankyouPages/withThankyouWrapper';

const adIds = [
  '312982',
  '312977',
  '312978',
  '312979',
  '312980',
  '312983',
  '312984',
];

const Nav = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  height: 130px;
  width: 100%;
  border-bottom: 5px inset #46b347;
  img {
    width: 200px;
    height: 75px;
  }
`;

const RtoThankyou = ({ path }) => {
  return (
    <>
      <Nav>
        <img src={r2oLogo} alt="Rent 2 Own" />
      </Nav>
      <AdButler adButlerIds={adIds} path={path} />
    </>
  );
};

export default withThankyouWrapper(RtoThankyou);
